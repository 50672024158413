import { FC, useEffect, useMemo, useRef, useState } from 'react';

import styles from './MainMarket.module.css';
import { Col, Empty, Form, Row, Table } from 'antd';
import { InputWithLabel } from 'components/Input';
import { FilterGroup } from 'components/FilterGroup';
import { Space } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'next-i18next';
import { ITickerSocket, usePairListQuery } from 'api/exchange';
import { addFavorite, removeFavorite, useWatchList } from 'api/account';
import { USER_COOKIES, WEB_SOCKET_URL } from 'utils/constant';
import { getCookies, parseJson, setCookies } from 'utils/cookies';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { useMutation, useQueryClient } from 'react-query';
import { useUser } from '@auth0/nextjs-auth0';

import { useAppDispatch, useAppSelector } from 'hooks';
import { getCurrentCurrency } from 'store/ducks/account/slice';
import { useGetCreditCoins } from 'api/get_credit';
import getColumns from './getColumn';

interface ICoinCurrency {
  BTC: number;
  ETH: number;
}

type IFilterType = 'all' | 'watch_list' | 'gainer' | 'loser';
interface ISortInfo {
  column: 'pair' | 'last' | 'dchange_pec' | 'high' | 'low' | 'vol';
  direction: number;
}

interface IMainMarket {
  limit: boolean;
}

const TOP_COINS = [
  'BTC_USDT',
  'ETH_USDT',
  'SOL_USDT',
  'ADA_USDT',
  'DOG_USDT',
  'DOT_USDT',
  'XRP_USDT',
  'SHIB_USDT',
  'MATIC_USDT',
  'AVAX_USDT',
];

const MainMarket: FC<IMainMarket> = ({ limit }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const currency = useAppSelector(getCurrentCurrency);
  const { sendMessage, lastMessage, readyState } = useWebSocket(WEB_SOCKET_URL);
  const { data: creditCoins } = useGetCreditCoins();

  const [filterType, setFilterType] = useState<IFilterType>('all');
  const [sortInfo, setSortInfo] = useState<ISortInfo>({ column: 'pair', direction: 0 });

  const [search, setSearch] = useState('');
  const [marketDatas, setMarketDatas] = useState<ITickerSocket[]>([]);
  const marketDatasRef = useRef<ITickerSocket[]>([]);

  const { user } = useUser();
  const { data: pairList, isLoading } = usePairListQuery({ leverage: 'ALL' });
  const { data: watchPairs } = useWatchList({
    onSuccess: (_data) => {
      if (_data) setCookies(USER_COOKIES.watchPairs, JSON.stringify(_data));
    },
    onError: () => {
      queryClient.setQueryData('/user/favorite/list', parseJson(getCookies(USER_COOKIES.watchPairs)));
    },
    enabled: !!user,
  });

  const [watchPairLoading, setWatchPairLoading] = useState<string[]>([]);
  const { mutateAsync: addWatchPair } = useMutation(addFavorite);
  const { mutateAsync: removeWatchPair } = useMutation(removeFavorite);

  useEffect(() => {
    if (!lastMessage || lastMessage?.data === "{'ping':''}") return;
    const tickerData: ITickerSocket = parseJson(lastMessage.data);

    const index = marketDatasRef.current.findIndex((item) => item.pair === tickerData.pair);

    const listData = [...marketDatasRef.current];
    if (index !== -1) {
      listData[index] = tickerData;
      marketDatasRef.current = listData;
    } else {
      marketDatasRef.current = [...marketDatasRef.current, tickerData];
    }
    setMarketDatas(marketDatasRef.current);
  }, [lastMessage, search]);

  // Subscribe websocket
  useEffect(() => {
    if (!pairList || readyState !== ReadyState.OPEN) return;

    if (limit) {
      pairList
        ?.filter((x: any[]) => TOP_COINS?.includes(x?.[0]))
        .forEach((pair: any) => {
          const msg = `{"event":"subscribe", "channel":"ticker","pair":"${pair[0]}"}`;
          sendMessage(msg);
        });
    } else {
      pairList.forEach((pair: any) => {
        const msg = `{"event":"subscribe", "channel":"ticker","pair":"${pair[0]}"}`;
        sendMessage(msg);
      });
    }
  }, [pairList, readyState, limit]);

  const handleWatchPair = async (pair: string) => {
    const queryKey = '/user/favorite/list';

    try {
      setWatchPairLoading((old) => Array.from(new Set([...old, pair])));
      let newWatchList: string[] = [];
      if (watchPairs?.includes(pair)) {
        if (user) await removeWatchPair(pair);
        const currentWatchPair = queryClient.getQueryData(queryKey) as string[];
        newWatchList = currentWatchPair.filter((currentPair) => currentPair !== pair);
      } else {
        if (user) await addWatchPair(pair);
        const currentWatchPair = queryClient.getQueryData(queryKey) as string[];
        newWatchList = [...(currentWatchPair || []), pair];
      }

      setCookies(USER_COOKIES.watchPairs, JSON.stringify(newWatchList));
      queryClient.setQueryData(queryKey, newWatchList);
      setWatchPairLoading((old) => old.filter((pair) => pair !== pair));
    } catch (err) {
      setWatchPairLoading((old) => old.filter((pair) => pair !== pair));
      console.log({ err });
    }
  };

  const coinCurrency = useMemo(() => {
    if (!marketDatasRef.current) return { BTC: 0, ETH: 0 };

    return marketDatasRef.current.reduce(
      (pre: ICoinCurrency, next: ITickerSocket) => {
        if (next.pair === 'BTC_USDT') {
          return { ...pre, BTC: Number(next.data.last) };
        }
        if (next.pair === 'ETH_USDT') {
          return { ...pre, ETH: Number(next.data.last) };
        }
        return pre;
      },
      { BTC: 0, ETH: 0 }
    );
  }, [marketDatas]);

  const columns = useMemo(() => {
    return getColumns(
      t,
      handleWatchPair,
      coinCurrency,
      sortInfo,
      setSortInfo,
      watchPairLoading,
      currency,
      dispatch,
      user,
      creditCoins,
      watchPairs
    );
  }, [coinCurrency, watchPairs, watchPairLoading, sortInfo, creditCoins, t]);

  const dataSource = useMemo(() => {
    let datas = [...marketDatas];

    // Search
    if (search) {
      datas = datas.filter((x) => x.pair.includes(search.toUpperCase()));
    }

    // Filter
    switch (filterType) {
      case 'loser':
        datas = datas.filter((x) => Number(x.data.dchange_pec) < 0);
        break;
      case 'gainer':
        datas = datas.filter((x) => Number(x.data.dchange_pec) > 0);
        break;
      case 'watch_list':
        datas = datas.filter((x) => watchPairs?.includes(x.pair));
        break;
      default:
        break;
    }

    // Sort
    let sortCallback = null;
    if (datas.length > 0 && sortInfo.direction !== 0) {
      let direction = sortInfo.direction;
      if (direction === 2) {
        direction = -1;
      }

      switch (sortInfo.column) {
        case 'pair':
          sortCallback = (a: ITickerSocket, b: ITickerSocket) => {
            if (a.pair < b.pair) return -direction;
            if (a.pair > b.pair) return direction;
            return 0;
          };
          break;
        case 'last':
          sortCallback = (a: ITickerSocket, b: ITickerSocket) => {
            let aValue = Number(a.data.last);
            let bValue = Number(b.data.last);
            if (aValue < bValue) return -direction;
            if (aValue > bValue) return direction;
            return 0;
          };
          break;
        case 'dchange_pec':
          sortCallback = (a: ITickerSocket, b: ITickerSocket) => {
            let aValue = Number(a.data.dchange_pec);
            let bValue = Number(b.data.dchange_pec);
            if (aValue < bValue) return -direction;
            if (aValue > bValue) return direction;
            return 0;
          };
          break;
        case 'high':
          sortCallback = (a: ITickerSocket, b: ITickerSocket) => {
            let aValue = Number(a.data.high);
            let bValue = Number(b.data.high);
            if (aValue < bValue) return -direction;
            if (aValue > bValue) return direction;
            return 0;
          };
          break;
        case 'low':
          sortCallback = (a: ITickerSocket, b: ITickerSocket) => {
            let aValue = Number(a.data.low);
            let bValue = Number(b.data.low);
            if (aValue < bValue) return -direction;
            if (aValue > bValue) return direction;
            return 0;
          };
          break;
        case 'vol':
          sortCallback = (a: ITickerSocket, b: ITickerSocket) => {
            let aValue = Number(a.data.vol);
            let bValue = Number(b.data.vol);
            if (aValue < bValue) return -direction;
            if (aValue > bValue) return direction;
            return 0;
          };
          break;
        default:
          break;
      }
    }
    if (sortCallback) {
      datas = datas.sort(sortCallback);
    }
    return datas;
  }, [search, marketDatas, filterType, sortInfo]);

  return (
    <div className="container">
      {!limit && (
        <Row gutter={[24, 0]} className={styles.introWrapper} justify="space-between">
          <Col lg={6}>
            <Form.Item>
              <InputWithLabel
                value={search}
                className={styles.searchInput}
                onChange={(e) => setSearch(e.target.value.replace(/[^a-zA-Z0-9_]/g, ''))}
                placeholder={t('marketpage.form.place_holder')}
                label={t('marketpage.form.search')}
                suffix={<FontAwesomeIcon color="#788686" icon={faSearch} className={styles.searchIcon} size="lg" />}
                searchForm
              />
            </Form.Item>
          </Col>
          <Col lg={6.5}>
            <Space size={30}>
              <FilterGroup
                datas={[
                  { label: t('marketpage.filter.all'), value: 'all' },
                  {
                    label: t('marketpage.filter.watch'),
                    value: 'watch_list',
                  },
                  {
                    label: t('marketpage.home.top_gainer'),
                    value: 'gainer',
                  },
                  {
                    label: t('marketpage.home.top_loser'),
                    value: 'loser',
                  },
                ]}
                value={filterType}
                onChange={(e) => {
                  setFilterType(e.target.value);
                }}
              />
            </Space>
          </Col>
        </Row>
      )}

      <div className="divider-x my-8" />
      <div className={styles.table}>
        <Table
          loading={isLoading}
          columns={columns}
          dataSource={dataSource}
          rowKey="pair"
          pagination={{
            pageSize: 15,
            hideOnSinglePage: true,
          }}
          size="small"
          scroll={{ x: true }}
          locale={{
            emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('common.no_data')} />,
          }}
        />
      </div>
    </div>
  );
};

export default MainMarket;
