import styles from './MainMarket.module.css';
import { Space, Button as AntdButton } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { currencyFormatter } from 'utils/currency';
import { Button } from 'components/Button';
import { PercentIndicator } from 'components/Statistic';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { ITickerSocket } from 'api/exchange';
import { HIDE_BUY_PAGE } from 'utils/constant';
import { currencyImgs } from 'assets/images/currency';
import { Avatar } from 'components/Avatar';
import numeral from 'numeral';
import clsx from 'clsx';
import SortIcon from 'components/SortIcon';
import Router from 'next/router';
import { routes } from 'types/routes';
import { ICurrentCurrency } from 'types/local';
import { setAuthModal } from 'store/ducks/system/slice';
import Link from 'next/link';

interface ICoinCurrency {
  BTC: number;
  ETH: number;
}

interface ISortInfo {
  column: 'pair' | 'last' | 'dchange_pec' | 'high' | 'low' | 'vol';
  direction: number;
}

const getColumns = (
  t: Function,
  handleWatch: (pair: string) => void,
  coinCurrency: ICoinCurrency,
  sortInfo: ISortInfo,
  setSortInfo: any,
  watchPairLoading: string[],
  currency: ICurrentCurrency,
  dispatch: any,
  user: any,
  creditCoins?: string[],
  watchPairs?: string[]
): ColumnsType<ITickerSocket> => [
  {
    title: (
      <SortIcon
        level={sortInfo.column === 'pair' ? sortInfo.direction : 0}
        onChangeOrder={(newOrder: any) => {
          setSortInfo({
            column: 'pair',
            direction: newOrder,
          });
        }}
      >
        {t('marketpage.marketTable.coin')}
      </SortIcon>
    ),
    dataIndex: 'pair',
    render(pair) {
      const pairSplit = pair.split('_');
      return (
        <Space>
          <AntdButton
            type="text"
            // disabled={watchPairLoading.includes(pair)}
            className={styles.btnWatch}
            onClick={() => handleWatch(pair)}
            icon={<FontAwesomeIcon color={(watchPairs || []).includes(pair) ? '#FFBA00' : '#9AA3A7'} icon={faStar} />}
          />
          <Link href={`${routes.exchange}/${pair}`}>
            <a className={styles.pairText}>
              <Space>
                <Avatar size={20} type="secondary" src={currencyImgs[pairSplit[0]] || currencyImgs.GENERIC} />
                <span className="bold default">{pairSplit[0]}</span>
              </Space>
              <span className="bold default">/</span>
              <Space>
                <Avatar size={20} type="secondary" src={currencyImgs[pairSplit[1]] || currencyImgs.GENERIC} />
                <span className=" bold default">{pairSplit[1]}</span>
              </Space>
            </a>
          </Link>
        </Space>
      );
    },
  },
  {
    title: (
      <SortIcon
        level={sortInfo.column === 'last' ? sortInfo.direction : 0}
        onChangeOrder={(newOrder: any) => {
          setSortInfo({
            column: 'last',
            direction: newOrder,
          });
        }}
      >
        {t('marketpage.marketTable.lastPrice')}
      </SortIcon>
    ),
    key: 'data',
    render(_, record) {
      const lastPrizes = record.data.last;

      if (Number(lastPrizes) === 0) return <span className="bold default uppercase"> __ &nbsp;/ __</span>;

      let lastPrizeUSDT = Number(lastPrizes) * Number(currency.rate);
      const bbType = record.pair.split('_')[1];
      if (bbType === 'BTC') {
        lastPrizeUSDT = Number(coinCurrency[bbType]) * Number(lastPrizeUSDT);
      }
      if (bbType === 'ETH') {
        lastPrizeUSDT = Number(coinCurrency[bbType]) * Number(lastPrizeUSDT);
      }

      return (
        <Space size="small">
          <span className={clsx(`bold success uppercase`)}>{numeral(lastPrizes).format('0,0.00[00000]')}</span>
          <span className="bold default">/</span>
          <div>
            <span className="default">{currency.symbol}</span>
            <span className="bold default uppercase">{`${numeral(lastPrizeUSDT).format('0,0.00[00000]')}`}</span>
          </div>
        </Space>
      );
    },
  },
  {
    title: (
      <SortIcon
        level={sortInfo.column === 'dchange_pec' ? sortInfo.direction : 0}
        onChangeOrder={(newOrder: any) => {
          setSortInfo({
            column: 'dchange_pec',
            direction: newOrder,
          });
        }}
      >
        {t('marketpage.marketTable.24h_change')}
      </SortIcon>
    ),
    key: 'data',
    render(_, record) {
      return <PercentIndicator transparent value={`${Number(record.data.dchange_pec).toFixed(2)}%`} />;
    },
  },
  {
    title: (
      <SortIcon
        level={sortInfo.column === 'high' ? sortInfo.direction : 0}
        onChangeOrder={(newOrder: any) => {
          setSortInfo({
            column: 'high',
            direction: newOrder,
          });
        }}
      >
        {t('marketpage.marketTable.24h_high')}
      </SortIcon>
    ),
    key: 'data',
    render(_, record) {
      return <span className="uppercase">{`${numeral(record.data.high).format('0,0.00[00000]')}`}</span>;
    },
  },
  {
    title: (
      <SortIcon
        level={sortInfo.column === 'low' ? sortInfo.direction : 0}
        onChangeOrder={(newOrder: any) => {
          setSortInfo({
            column: 'low',
            direction: newOrder,
          });
        }}
      >
        {t('marketpage.marketTable.24h_low')}
      </SortIcon>
    ),
    key: 'data',
    render(_, record) {
      return <span className="uppercase">{`${numeral(record.data.low).format('0,0.00[00000]')}`}</span>;
    },
  },
  // {
  //   title: t('marketpage.marketTable.market_cap'),
  //   key: 'marketCap',
  //   dataIndex: 'marketCap',
  // },
  {
    title: (
      <SortIcon
        level={sortInfo.column === 'vol' ? sortInfo.direction : 0}
        onChangeOrder={(newOrder: any) => {
          setSortInfo({
            column: 'vol',
            direction: newOrder,
          });
        }}
      >
        {t('marketpage.marketTable.24h_volume')}
      </SortIcon>
    ),
    key: 'data',
    render(_, record) {
      return (
        <>
          <span>{currency.symbol}</span>
          <span className="uppercase">{currencyFormatter(`${Number(record.data.vol) * Number(currency.rate)}`)}</span>
        </>
      );
    },
  },
  {
    key: 'actions',
    align: 'right',
    colSpan: 5,
    render(_, record) {
      const coin = record.pair.split('_')[0];
      return (
        <Space size="large">
          <Button
            disabled={HIDE_BUY_PAGE === 1 || !creditCoins?.includes(coin)}
            onClick={() => {
              if (!user) return dispatch(setAuthModal({ type: 'auth' }));
              else Router.push(`${routes.buy}?coin=${coin}`);
            }}
            type="accent-turqoise"
            size="small"
          >
            {t('marketpage.marketTable.buy')}
          </Button>
          <Button onClick={() => Router.push(`${routes.exchange}/${record.pair}`)} type="success" size="small">
            {t('marketpage.marketTable.trade')}
          </Button>
        </Space>
      );
    },
  },
];

export default getColumns;
